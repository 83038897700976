import { computed, unref, type MaybeRef } from 'vue'
import { useGetCar } from '@autobid/ui/composables/useCar'
import { useGetCarLink } from './useGetCarLink'

export function useCarPaginationLinks(
  carId: MaybeRef<number>,
  type: 'details' | 'barometer'
) {
  const { getCar } = useGetCar()
  const pagination = computed(() => {
    const car = getCar(unref(carId))

    return car.pagination
  })

  const { getLink } = useGetCarLink()

  const prevLink = computed(() => {
    return getLink(unref(pagination).previous, type)
  })

  const nextLink = computed(() => {
    return getLink(unref(pagination).next, type)
  })

  return {
    pagination,
    nextLink,
    prevLink
  }
}
