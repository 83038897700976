import type { StrapiPageMetaData } from '@autobid/strapi-integration/typescript/strapi/Page'
import type { AuctionCar } from '@autobid/ui/types/Car'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { formatPrice } from '@autobid/ui/utils/formatPrice'
import type { Ref } from 'vue'

function getMetaLabeledValue([label, value]: [
  string,
  string | number | undefined
]) {
  return value ? `${label}: ${value}` : null
}

export const useCarSeoMeta = (
  strapiSeo: StrapiPageMetaData | null,
  car: Ref<AuctionCar | undefined>,
  auction: Ref<DisplayingAuction>
) => {
  if (!car.value) return // car can be unpublished and removed from application state via WS

  const { t } = useI18n()
  const titlePrefix = strapiSeo?.metaTitle ? `${strapiSeo?.metaTitle} - ` : ''

  const setMetaProperty = (
    elements: Array<string | number | undefined | null>
  ) => {
    const result = elements.filter(Boolean).join(', ')
    return result.length ? result : undefined
  }

  useSeoMeta({
    title: () => setMetaProperty([titlePrefix, car.value?.name]),
    ogTitle: () => setMetaProperty([titlePrefix, '-', car.value?.name]),
    description: () =>
      setMetaProperty([
        strapiSeo?.metaDescription,
        auction.value?.title,
        getMetaLabeledValue([
          t('auction-list.auction-number'),
          auction.value?.number
        ]),
        getMetaLabeledValue([
          t('barometer.catalog-number'),
          car.value?.catalogNumber
        ]),
        auction.value?.startDate,
        car.value?.name,
        car.value ? formatPrice(car.value?.price.minimal) : undefined
      ]),
    ogDescription: () =>
      setMetaProperty([
        strapiSeo?.metaDescription,
        getMetaLabeledValue([
          t('auction-list.auction-number'),
          auction.value?.number
        ]),
        getMetaLabeledValue([
          t('barometer.catalog-number'),
          car.value?.catalogNumber
        ]),
        auction.value?.startDate,
        car.value?.name,
        car.value ? formatPrice(car.value?.price.minimal) : undefined
      ]),
    ogImage: () =>
      setMetaProperty([
        Object.values(car.value?.imageGroups.itemMainImageSubgroup ?? {})?.[0]
          ?.links?.hd
      ]),
    ogImageAlt: () => setMetaProperty([car.value?.name]),
    keywords: () =>
      setMetaProperty([
        strapiSeo?.keywords,
        car.value?.name,
        car.value?.category?.name,
        car.value?.equipments.eq70?.name
      ])
  })
}
